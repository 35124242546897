document.addEventListener('DOMContentLoaded', function() {

    // Navbar show and hide
    var prevScrollPos = window.pageYOffset;
    window.onscroll = function() {
        var currentScrollPos = window.pageYOffset;
        var headerHeight = document.getElementById('header').clientHeight;

        if ( (prevScrollPos > currentScrollPos) && (currentScrollPos > headerHeight) ) { // If scrolling up and header not in viewport, show the navbar
            document.getElementById('top-navbar').style.top = '0';
        } else { // Hide the navbar
            document.getElementById('top-navbar').style.top = '-3.4rem';
        }

        prevScrollPos = currentScrollPos;
    };

    // Obfuscate phone number in WhatsApp link
    document.querySelector('.wa-link').href= 'https:' + '//' + 'wa.me' + '/' + '628' + '112' + '03' +'0' +'43' + '0';

    AOS.init({
        duration: 500,
        delay: 100,
        once: true
    });

    //Initialise Materialize CSS Sidenav Component
    const naviconButton = document.getElementsByClassName('navicon__button')[0];
    var sidenavElems = document.querySelectorAll('.sidenav');
    var sidenavInstances = M.Sidenav.init(sidenavElems, {
        edge: 'right',
        onOpenStart: function() {
            // Add class name 'on' to navicon__button
            naviconButton.classList.add('on'); // This will animate the navicon into an X
            naviconButton.classList.remove('sidenav-trigger');
            document.getElementsByClassName('on')[0].onclick = function() { // Close the sidenav when clicking on X
                sidenavInstances[0].close();
            };
        },
        onCloseStart: function() {
            // Remove class name 'on' from navicon__button
            naviconButton.classList.remove('on');
        },
        onCloseEnd: function() {
            naviconButton.classList.add('sidenav-trigger');
        }
    });

});